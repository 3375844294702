import React, { useEffect } from "react";
import { Container, Text, Image } from "@atoms";
import { HomeAudioLinks, BackgroundVideo } from "@molecules";
import classNames from "classnames";
import useLang from "@hooks/useLang";
import { useAppState } from "@state";
import { m, stagger, useAnimate } from "framer-motion";

const HeroVideo = ({
  label,
  heading,
  subheading,
  transcriptText,
  transcriptSubtext,
  audio,
  audioTitle,
  transcript,
  image,
  videoId,
}) => {
  const [scope, animate] = useAnimate();
  const lang = useLang();

  const [, dispatch] = useAppState();

  const openTranscript = () => {
    dispatch({
      type: "openTranscript",
      ...transcript,
    });
  };

  useEffect(() => {
    const timeline = [
      [
        "#portal",
        {
          maskSize: ["15rem", "200vmax"],
          webkitMaskSize: ["15rem", "200vmax"],
          webkitMaskImage: `url("/images/portal.svg")`,
          webkitMaskRepeat: "no-repeat",
          webkitMaskPosition: "center",
        },
        { delay: 4, duration: 1, ease: "backInOut" },
      ],
      [
        "#portal > div",
        { opacity: [1, 0.7] },
        { ease: "easeInOut", at: "-0.5 " },
      ],
      [
        ".fade-in",
        { opacity: 1 },
        { delay: stagger(0.08), ease: "circOut", at: "+0.2" },
      ],
      [
        ".footer",
        { translateY: ["100%", "0%"] },
        { ease: "circOut", at: "<0.4" },
      ],
    ];
    animate(timeline);
  }, []);

  return (
    <div
      ref={scope}
      className="relative z-40 flex min-h-screen w-full items-center overflow-hidden"
    >
      <m.div
        id="portal"
        className="absolute inset-0 z-0 m-auto flex h-full w-full items-center justify-center bg-black"
        style={{
          maskImage: `url("/images/portal.svg")`,
          maskRepeat: "no-repeat",
          maskPosition: "center",
          maskSize: "15rem",
          webkitMaskImage: `url("/images/portal.svg")`,
          webkitMaskRepeat: "no-repeat",
          webkitMaskPosition: "center",
          webkitMaskSize: "15rem",
        }}
      >
        <div>
          {videoId && <BackgroundVideo videoId={videoId?.split("/").pop()} />}
          {image && !videoId && <Image image={image} fill />}
        </div>
      </m.div>

      <Container variant="xl" className="z-10">
        {label && (
          <div
            className={classNames({
              "max-w-[15rem] sm:max-w-[8.65rem]": lang === "en",
              "max-w-md sm:max-w-sm": lang !== "en",
            })}
          >
            <Text className="fade-in mb-8 font-bold uppercase leading-tight text-white opacity-0 ">
              {label}
            </Text>
          </div>
        )}
        <div
          className={classNames({
            "max-w-lg": lang === "en",
            "max-w-3xl": lang !== "en",
            "mt-3": lang === "fr",
          })}
        >
          <Text
            tag="h1"
            className={classNames(
              "fade-in text-[3rem] font-bold uppercase leading-none text-white opacity-0 sm:text-[4rem] md:text-[5rem] lg:text-[5.75rem]"
            )}
          >
            {heading}
          </Text>
          {subheading && (
            <Text
              tag="h2"
              className={classNames(
                "fade-in mt-3 text-2xl font-bold leading-none text-white opacity-0 "
              )}
            >
              {subheading}
            </Text>
          )}
        </div>
        <m.div className="footer absolute bottom-0 left-0 mt-14 flex w-full translate-y-full flex-col gap-4 border-t-3 border-white md:left-14 md:flex-row md:items-center">
          <div className="relative p-2.5 sm:p-4">
            <div className="absolute right-0 top-0 hidden h-full w-[3px] bg-white md:block" />
            <HomeAudioLinks
              heading={transcriptText}
              subHeading={transcriptSubtext}
              transcript={transcript}
              audio={audio}
              title={audioTitle}
              iconSize="sm"
              color="white"
              hideOnMobile
            />
          </div>
          <div className="hidden p-2.5 sm:p-4 md:block">
            <button
              type="button"
              className="text-white underline decoration-2 underline-offset-4 hover:decoration-transparent"
              onClick={openTranscript}
            >
              <Text variant="button">{transcriptText}</Text>
            </button>
          </div>
        </m.div>
      </Container>
    </div>
  );
};

HeroVideo.defaultProps = {};

export default HeroVideo;
